import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Spinner, Alert } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  UserAvatar,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders, getPhoneCodeAndNumber, getQueryParams, formatTo2DP, moneyToFloat, orderStatusOptions } from "../utils/Utils";
import { Link } from "react-router-dom";
import { AutoComplete, Empty, Input, Select, Space, InputNumber, Divider, Spin, Form } from 'antd';
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "./components/AlertModals";
import axios from 'axios';
import moment from 'moment';
import imagePlaceholder from "../images/product_placeholder.png";
import Numeral from 'react-numeral';
import { CreateTaxProfileModal } from "./components/TaxModals";
import { CreateNewProductFullModal } from "./components/ProductModals";
import { CreateNewServiceModal } from "./components/ServiceModals";
import PhoneNumberInput from "./components/PhoneNumberInput";
import { addOfflineSale, deleteCustomersByBusinessID, deleteProductsByBusinessID, deleteTaxProfilesByBusinessID, getCustomers_OffDB, getProducts_OffDB, getTaxProfiles_OffDB, reduceProductQuantities, updateCustomers_OffDB, updateProducts_OffDB, updateTaxProfiles_OffDB } from "../utils/OfflineDB";
const { TextArea } = Input;
const { Option } = Select;

const POS = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUsage, setLoadingUsage] = useState(true);
  const [fetchingCustomers, setFetchingCustomers] = useState(false);
  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [usage, setUsage] = useState({used: 0, total: 0, package: ''});
  const [form] = Form.useForm();
  const [dueDate, setDueDate] = useState(new Date());
  const [dueDateTime, setDueDateTime] = useState(new Date());
  const [issueDate, setIssueDate] = useState(new Date());
  const [issueDateTime, setIssueDateTime] = useState(new Date());

  const [fulfillmentDate, setFulfillmentDate] = useState(new Date());
  const [fulfillmentDateTime, setFulfillmentDateTime] = useState(new Date());
  const [orderReminderDate, setOrderReminderDate] = useState('');
  const [orderReminderDateTime, setOrderReminderDateTime] = useState('');
  const [customersList, setCustomersList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [taxesList, setTaxesList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(new Set());
  const [errorMessage, setErrorMessage] = useState("");
  const [itemsErrorMessage, setItemsErrorMessage] = useState("");
  const [itemFields, setItemFields] = useState([]);
  const [selectedTaxProfile, setSelectedTaxProfile] = useState({
    tax_id: null,
    tax_profile_name: null,
    taxes: [],
  });
  const [saleSummary, setSaleSummary] = useState({
    sub_total: 0,
    total_direct_tax: 0,
    total_compound_tax: 0,
    total_tax: 0,
    taxes: [],
    discount_amount: 0,
    discount_percentage: 0,
    total_amount: 0,
    amount_received: 0,
    amount_paid: 0,
    balance_due: 0,
    change: 0
  });
  const [alertCustomerCheck, setAlertCustomerCheck] = useState(true);
  const [formData, setFormData] = useState({
    customer_name: '',
    phone_code: "+233",
    phone_number: '',
    customer_email: '',
    items: [],
    order_status: 'outstanding',
    fulfillment_type: 'delivery',
    location_name: '',
    location: '',
    lng: null,
    lat: null,
    fulfillment_cost: 0,
    fulfillment_date: new Date(),
    fulfillment_date_time: new Date(),
    order_reminder_date: new Date(),
    order_reminder_date_time: new Date(),
    customer_order_note: '',
    alert_team: true,
    discount_type: 'fixed',
    discount_amount: 0,
    tax_profile: null,
    note: '',
    issue_date: new Date(),
    issue_date_time: new Date(),
    due_date: new Date(),
    due_date_time: new Date(),
    payment_type: 'cash',
    amount_received: 0,
    alert_customer: true,
    sale_type: 'invoice',
    save_type: 'save',
  });
  const [alertTeamChecked, setAlertTeamChecked] = useState(false);
  const [activeModal, setActiveModal] = useState(null);

  // State to manage internet connection status
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleFormSubmit = async(_formData) => {
    setRequesting(true);
    setErrorMessage("");
    setItemsErrorMessage("")

    let phoneNumber = '';
    if(_formData.phone_code && _formData.phone_number){
      phoneNumber = `${_formData.phone_code}${_formData.phone_number?.replace(/^0+/, '')}`;
    }

    let body = {
      business_id: businessID,
      customer_name: _formData.customer_name,
      phone_number: phoneNumber,
      customer_email: _formData.customer_email,
      items: JSON.stringify(_formData.items),
      fulfillment_type: null,
      location_name: null,
      location: null,
      lat: null,
      lng: null,
      fulfillment_cost: null,
      discount_type: _formData.discount_type,
      discount_amount: _formData.discount_amount,
      tax_profile: _formData.tax_profile,
      note: _formData.note,
      issue_date: `${moment(_formData.issue_date).format("YYYY-MM-DD")} ${moment(_formData.issue_date_time).format("HH:mm")}`,
      due_date: `${moment(_formData.due_date).format("YYYY-MM-DD")} ${moment(_formData.due_date_time).format("HH:mm")}`,
      payment_type: _formData.payment_type,
      amount_received: _formData.amount_received,
      alert_customer: _formData.alert_customer ? 'yes' : 'no',
      sale_type: 'invoice'
    }

    if(!navigator.onLine){
      let saleID = await addOfflineSale(body);
      await reduceProductQuantities(_formData.items, businessID);
      history.push(`${process.env.PUBLIC_URL}/sales/b/${businessID}/offline-details/${saleID}?saved=yes&print=${_formData.save_type === 'print' ? 'yes' : 'no'}&offline=yes`);
      return;
    }

    axios.post(baseURLs.API_URL + `/sales/pos`, {
      ...body
    }, getAxiosHeaders())
    .then((response) => {  
      // redirect to sale details page
      let responseInfo = response.data;
      let saleID = responseInfo.data.sale_id;
      history.push(`${process.env.PUBLIC_URL}/sales/b/${businessID}/details/${saleID}?saved=yes&print=${_formData.save_type === 'print' ? 'yes' : 'no'}`);
      return;

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          
          if(errorResponse.errors.hasOwnProperty("items")){
            setItemsErrorMessage(errorResponse.errors.items)
          } else {
            setItemsErrorMessage("")
          }

          form.setFields([
            {
              name: 'customer_name',
              errors: errorResponse.errors.hasOwnProperty("customer_name") ? [errorResponse.errors.customer_name] : [],
            },
            {
              name: 'phone_number',
              errors: errorResponse.errors.hasOwnProperty("phone_number") ? [errorResponse.errors.phone_number] : [],
            },
            {
              name: 'customer_email',
              errors: errorResponse.errors.hasOwnProperty("customer_email") ? [errorResponse.errors.customer_email] : [],
            },
            {
              name: 'fulfillment_type',
              errors: errorResponse.errors.hasOwnProperty("fulfillment_type") ? [errorResponse.errors.fulfillment_type] : [],
            },
            {
              name: 'location_name',
              errors: errorResponse.errors.hasOwnProperty("location_name") ? [errorResponse.errors.location_name] : [],
            },
            {
              name: 'location',
              errors: errorResponse.errors.hasOwnProperty("location") ? [errorResponse.errors.location] : [],
            },
            {
              name: 'fulfillment_cost',
              errors: errorResponse.errors.hasOwnProperty("fulfillment_cost") ? [errorResponse.errors.fulfillment_cost] : [],
            },
            {
              name: 'discount_type',
              errors: errorResponse.errors.hasOwnProperty("discount_type") ? [errorResponse.errors.discount_type] : [],
            },
            {
              name: 'discount_amount',
              errors: errorResponse.errors.hasOwnProperty("discount_amount") ? [errorResponse.errors.discount_amount] : [],
            },
            {
              name: 'tax_profile',
              errors: errorResponse.errors.hasOwnProperty("tax_profile") ? [errorResponse.errors.tax_profile] : [],
            },
            {
              name: 'note',
              errors: errorResponse.errors.hasOwnProperty("note") ? [errorResponse.errors.note] : [],
            },
            {
              name: 'issue_date',
              errors: errorResponse.errors.hasOwnProperty("issue_date") ? [errorResponse.errors.issue_date] : [],
            },
            {
              name: 'due_date',
              errors: errorResponse.errors.hasOwnProperty("due_date") ? [errorResponse.errors.due_date] : [],
            },
            {
              name: 'payment_type',
              errors: errorResponse.errors.hasOwnProperty("payment_type") ? [errorResponse.errors.payment_type] : [],
            },
            {
              name: 'amount_received',
              errors: errorResponse.errors.hasOwnProperty("amount_received") ? [errorResponse.errors.amount_received] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        window.scrollTo({top: 0, behavior: 'smooth'});
        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setRequesting(false);
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  const updateProductList = (productName) => {
    toggleModal('successProductModal');
    getProducts({businessID, productName});
  }

  const getCustomers = async ({businessID, customerName, updateOfflineDB = false}) => {

    const customerList = await getCustomers_OffDB(businessID, customerName);    
    if (customerList.length > 0) {
      setCustomersList(customerList);
      return;
    }

    if(!navigator.onLine){
      if(fetchingCustomers){
        setFetchingCustomers(false);
      }
      return;
    }
    
    return axios.get(baseURLs.API_URL + "/customers", {
      params: {
        business_id: businessID,
        customer: customerName,
        access: 'record_sales',
        limit: 500
      },
      headers: getAxiosHeaders().headers
    })
    .then(async(response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let customers = responseInfo.data.customers;

        const _customers = customers.map(customer => ({
          business_id: businessID,
          customer_id: customer.customer_id,
          customer_code: customer.customer_code,
          customer_name: customer.customer_name,
          customer_phone_number: customer.customer_phone_number,
          customer_email: customer.customer_email,
        }));

        if(updateOfflineDB) {
          await deleteCustomersByBusinessID(businessID);
        }

        await updateCustomers_OffDB(_customers);
        
        if(customerList.length === 0 && _customers.length > 0) {
          setCustomersList(_customers);
        }
      }

      if(fetchingCustomers){
        setFetchingCustomers(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingCustomers){
          setFetchingCustomers(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getProducts = async ({businessID, productName, updateOfflineDB = false}) => {

    const productList = await getProducts_OffDB(businessID, productName);    
    if (productList.length > 0) {
      setProductsList(productList);
    }

    if(!navigator.onLine){
      if(fetchingProducts){
        setFetchingProducts(false);
      }
      return;
    }

    return axios.get(baseURLs.API_URL + "/products/variants", {
      params: {
        business_id: businessID,
        product_name: productName,
        access: 'record_sales',
        limit: 500
      },
      headers: getAxiosHeaders().headers
    })
    .then(async(response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let products = responseInfo.data.products;

        const _products = products.map(product => ({
          business_id: businessID,
          product_id: product.product_id,
          product_name: product.product_name,
          selling_price: product.selling_price,
          cost_price: product.cost_price,
          thumbnail: product.images.length > 0 ? product.images[0] : null,
          quantity: product.quantity,
          stock_status: product.stock_status,
          restock_level: product.restock_level,
          barcode: product.barcode,
          SKU: product.SKU,
          category: product.category,
        }));

        if(updateOfflineDB) {
          await deleteProductsByBusinessID(businessID);
        }

        await updateProducts_OffDB(_products);          

        if(productList.length === 0 && _products.length > 0) {
          setProductsList(_products);
        }

      }

      if(fetchingProducts){
        setFetchingProducts(false);
      }
    }).catch((error) => {
      console.log({error});
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingProducts){
          setFetchingProducts(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getTaxProfiles = async (businessID, updateOfflineDB = false) => {

    const taxProfileList = await getTaxProfiles_OffDB(businessID);    
    if (taxProfileList.length > 0) {
      setTaxesList(taxProfileList);
    }
    
    if(!navigator.onLine){
      return;
    }

    return axios.get(baseURLs.API_URL + `/businesses/taxes`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID,
        access: 'record_sales',
      },
    })
    .then( async(response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let taxes = responseInfo.data.taxes;

        const _taxes = taxes.map(tax => ({
          business_id: businessID,
          tax_id: tax.tax_id,
          tax_profile_name: tax.tax_profile_name,
          taxes: tax.taxes,
        }));

        if(updateOfflineDB) {
          await deleteTaxProfilesByBusinessID(businessID);
        }

        await updateTaxProfiles_OffDB(_taxes);

        if(taxProfileList.length === 0 && _taxes.length > 0) {
          setTaxesList(_taxes);
        }
        
        if(formData.tax_profile){
          const selectedTax = taxes.find(tax => tax.tax_id === formData.tax_profile);
          if (selectedTax) {
            let { tax_id, tax_profile_name, taxes } = selectedTax;
            setSelectedTaxProfile({
              ...selectedTaxProfile,
              tax_id: tax_id,
              tax_profile_name: tax_profile_name,
              taxes: taxes,
            });
          }
        }
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const updateTaxProfiles = () => {
    toggleModal('successTaxModal')
    getTaxProfiles(businessID);
  }

  const handleChangedFields = (changedFields, allFields) => {
    const fieldsToWatch = ['items', 'location_name', 'fulfillment_cost', 'discount_amount', 'discount_type', 'tax_profile', 'amount_received'];
          
    const shouldRecalculate = changedFields.some(field =>
      fieldsToWatch.some(watchedField => field.name[0] === watchedField)
    );

    if (shouldRecalculate) {
      updateSaleSummary();
    }    
  }

  const updateSaleSummary = () => {
    const items = form.getFieldValue('items') || [];
    let fulfillmentCost = formatTo2DP(form.getFieldValue('fulfillment_cost') || 0);
    let discountAmount = formatTo2DP(form.getFieldValue('discount_amount') || 0);
    const discountType = form.getFieldValue('discount_type') || '';
    let amountReceived = formatTo2DP(form.getFieldValue('amount_received') || 0);

    let calculatedTaxAmounts = [];
    
    // Calculate Subtotal including Fulfillment Cost
    let subTotal = items.reduce((total, item) => {
      const price = item.price || 0;
      const quantity = item.quantity || 0;
      return total + price * quantity;
    }, 0) + formatTo2DP(fulfillmentCost);
    
    const { taxes } = selectedTaxProfile;
    
    // Calculate Direct Taxes on Subtotal including Fulfillment Cost
    let directTaxesTotal = 0;
    taxes.forEach(tax => {
      if (tax.type === 'direct') {
        let taxAmount = formatTo2DP((subTotal * tax.percentage) / 100);
        calculatedTaxAmounts.push({
          tax: tax.tax_name,
          percentage: tax.percentage,
          amount: taxAmount,
          type: 'direct',
        });
        
        directTaxesTotal += taxAmount;
        
      }
    });
  
    // Calculate Subtotal with Direct Taxes and Fulfillment Cost
    let subTotalWithDirectTaxes = formatTo2DP(subTotal + directTaxesTotal);
  
    // Calculate Compound Taxes on Subtotal with Direct Taxes and Fulfillment Cost
    let compoundTaxesTotal = 0;
    taxes.forEach(tax => {
      if (tax.type === 'compound') {
        let taxAmount = formatTo2DP((subTotalWithDirectTaxes * tax.percentage) / 100);
        calculatedTaxAmounts.push({
          tax: tax.tax_name,
          percentage: tax.percentage,
          amount: taxAmount,
          type: 'compound',
        });

        compoundTaxesTotal += taxAmount;
      }
    });
  
    // Calculate Total Tax Amount
    let totalTaxAmount = formatTo2DP(directTaxesTotal + compoundTaxesTotal);
  
    // Calculate Total Amount including Taxes, Fulfillment Cost, and Discounts
    let totalAmount = formatTo2DP(subTotalWithDirectTaxes + compoundTaxesTotal);
    
    let discountPercentage = 0;
    if(totalAmount !== 0){
      if (discountType === 'fixed') {
        discountPercentage = formatTo2DP((discountAmount * 100) / totalAmount);
        totalAmount -= formatTo2DP(discountAmount); // Deduct fixed discount amount
      } else if (discountType === 'percentage') {
        discountPercentage = discountAmount;
        discountAmount = formatTo2DP((totalAmount * discountAmount) / 100);
        totalAmount -= discountAmount; // Deduct discount percentage
      }
    }

    // Calculate balance due
    let balanceDue = formatTo2DP(totalAmount - amountReceived);
    let amountPaid = totalAmount >= amountReceived ? amountReceived : totalAmount;
  
    // Update sale summary state
    setSaleSummary({
      ...saleSummary,
      sub_total: subTotal,
      total_direct_tax: directTaxesTotal,
      total_compound_tax: compoundTaxesTotal,
      total_tax: totalTaxAmount,
      taxes: calculatedTaxAmounts,
      discount_amount: discountAmount,
      discount_percentage: discountPercentage,
      total_amount: totalAmount,
      amount_received: amountReceived,
      amount_paid: amountPaid,
      balance_due: balanceDue < 0 ? 0 : balanceDue,
      change: balanceDue < 0 ? balanceDue * -1 : 0
    })
  }

  const debouncedFetchCustomers = debounce(getCustomers, 1000);
  const debouncedFetchProducts = debounce(getProducts, 1000);
  const debouncedHandleChangedFields = debounce(handleChangedFields, 1000);

  const handleCustomerNameChange = (customerName) => {
    // Fetch more customers when the user enters a customer name not found in the initial list
    if (!customersList.some(customer => customer.customer_name === customerName)) {
      debouncedFetchCustomers({businessID, customerName});
      setFetchingCustomers(true);
    }
  };

  const handleCustomerSelect = (value, option) => {
    const selected = customersList.find(customer => customer.customer_name === value);
    
    if (selected) {
      const { customer_phone_number, customer_email } = selected;
      let phoneNumberFormatted = getPhoneCodeAndNumber(customer_phone_number);
      
      form.setFieldValue("phone_code", phoneNumberFormatted.phoneCode);
      form.setFieldValue("phone_number", phoneNumberFormatted.phoneNumber);
      
      setFormData({
        ...formData,
        phone_code: phoneNumberFormatted.phoneCode,
        phone_number: phoneNumberFormatted.phoneNumber,
      });
    }
  };

  const handleProductChange = (productName) => {
    // Fetch more products when the user enters a product name not found in the initial list
    if (!productsList.some(product => product.product_name === productName)) {
      debouncedFetchProducts({businessID, productName});
      setFetchingProducts(true);
    }
  };

  const handleProductSelect = (value, option) => {
    const selectedProduct = productsList.find(product => product.product_name === value);
    console.log({
      selectedProduct,
      value
    })
    if (selectedProduct) {
      let { product_id, images, product_name, selling_price, quantity } = selectedProduct;
      selling_price = moneyToFloat(selling_price);

      // Assuming you have a function to set the form data or state
      const updatedItemFields = form.getFieldValue("items");
      
      let newItem = {
        images,
        item_type: 'product',
        item_id: product_id,
        item_name: product_name,
        price: selling_price,
        quantity: 1,
        total_stock: quantity,
        description: null
      };
      
      
      // Update the formData state with the updated items array
      setFormData({
        ...formData,
        items: [...updatedItemFields, newItem],
      });
      
      form.setFieldsValue({
        items: [...updatedItemFields, newItem],
      });

    }
  };

  const handleApplyTotal = () => {
    form.setFieldValue("amount_received", saleSummary.total_amount);
    updateSaleSummary();
  };

  const handleRemoveItem = (index) => {
    const oldItemFields = form.getFieldValue("items");
    const newFields = [...oldItemFields];
    newFields.splice(index, 1);
    setItemFields(newFields);

    form.setFieldsValue({
      items: newFields,
    });

    setFormData({
      ...formData,
      items: newFields,
    });

    updateSaleSummary();
  };

  const handleTaxProfileSelect = (value, option) => {
    const selectedTax = taxesList.find(tax => tax.tax_id === value);
    if (selectedTax) {
      let { tax_id, tax_profile_name, taxes } = selectedTax;

      setSelectedTaxProfile({
        ...selectedTaxProfile,
        tax_id: tax_id,
        tax_profile_name: tax_profile_name,
        taxes: taxes,
      });
      
    } else {
      setSelectedTaxProfile({
        ...selectedTaxProfile,
        tax_id: null,
        tax_profile_name: null,
        taxes: [],
      });
    }
  }

  const handleSaleSubmitType = (type) => {
    form.setFieldValue('save_type', type);
    form.submit();
  }

  useEffect(() => {
    updateSaleSummary();
  },[selectedTaxProfile, formData.items]);

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    // Add event listeners for online/offline
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    const { productNames } = itemFields.reduce(
      (acc, item) => {
        if (item.item_name) {
          const targetSet = acc.productNames;
          targetSet.add(item.item_name);
        }
        return acc;
      },
      {
        productNames: new Set(),
      }
    );
  
    setSelectedProducts(productNames);
  }, [itemFields]);

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);

    Promise.all([
      getCustomers({businessID: businessID, updateOfflineDB: true}),
      getProducts({businessID: businessID, updateOfflineDB: true}),
      getTaxProfiles(businessID, true),
    ]).finally(() => {
      setLoading(false);
    });
  }, [match.params.businessID]);

  const discountTypeOptions = (
    <Form.Item name={'discount_type'}
        noStyle
        rules={[
        {required: false}
      ]}>
      <Select className="select-after"
        popupMatchSelectWidth={false}
        style={{
          width: 'fit-content',
        }}>
        <Option value="fixed">{businessInfo.currency}</Option>
        <Option value="percentage">%</Option>
      </Select>
    </Form.Item>
  );

  return (<React.Fragment>
    <Head title="POS" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
      <BlockHead size="sm">
        <div className="nk-block-between mb-4">
          <BlockHeadContent>
            <BlockDes className="text-soft">
              <p>{businessInfo.name}</p>
            </BlockDes>
            <BlockTitle page tag="h3">
              POS
            </BlockTitle>
            
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="more-v"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/dashboard/b/${businessID}`}>
                      <button className="btn btn-outline-light">
                        <Icon name="arrow-left" />
                        <span>Go to Dashboard</span>                          
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}`}>
                      <Button color="primary">
                        <Icon name="coins"></Icon>
                        <span>View Sales</span>
                      </Button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </div>
      </BlockHead>

      <Block>
        {errorMessage && (
          <Alert color="danger" className="alert-icon">
            {" "}
            <Icon name="alert-circle" /> {errorMessage}{" "}
          </Alert>
        )}
        <Form name="pos"
          form={form} className="is-alter"
          initialValues={formData} 
          onFinish={handleFormSubmit}
          onFieldsChange={(changedFields, allFields) => {
            debouncedHandleChangedFields(changedFields, allFields);       
          }}
          onFinishFailed={onFinishFailed}>
          <Form.Item name="save_type" style={{ display: 'none' }}>
            <Input type="hidden" />
          </Form.Item>
          <div className="row">
            <div className="col-md-8">
              <div className="card card-bordered">
                <div className="fm-head bg-lighter">
                  <div className="form-group w-100">
                    <div className="form-label-group">
                      <label className="form-label">Enter Product Name </label>
                    </div>
                    <Space.Compact className="w-100">
                      <Select
                        size="large"
                        onChange={(value) => handleProductChange(value)}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0', }} />
                            <Space style={{ padding: '0 8px 4px', }}>
                              <Button className="btn-dim btn-blank" onClick={() => {toggleModal('createNewProductModal')}}>
                                <Icon name="plus" />
                                <span>Create New Product</span>
                              </Button>
                            </Space>
                          </>
                        )}
                        options={
                          productsList.map(prod => {
                            // Check if the product is selected in another field
                            const isAlreadySelected = formData.items.some(item => item.item_id === prod.product_id);
                            const isOutOfStock = prod.stock_status === 'out_of_stock' ? true : false;

                            return {
                              key: prod.product_id,
                              label: isAlreadySelected ? `${prod.product_name} - (Selected)` : `${prod.product_name} ${isOutOfStock ? `- (Out of Stock)` : ``}`,
                              value: prod.product_name,
                              disabled: isAlreadySelected || isOutOfStock, // Disable the option if it's already selected
                            };
                          })
                        }
                        onSelect={(value, option) => handleProductSelect(value, option)}
                        onSearch={handleProductChange}
                        showSearch={true}
                        value=""
                        notFoundContent={
                          fetchingProducts ? 
                          <div className="text-center m-5">
                            <Spin/>
                          </div>
                          :
                          <div className="text-center m-5">
                            <div className="price-plan-media"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                          </div>
                        }
                        placeholder="Enter product name"
                        className="w-100"
                      />
                      {/* <button type="button" className="btn btn-sm btn-outline-primary">
                        <span>Scan Mode</span>
                      </button> */}
                    </Space.Compact>
                  </div>

                </div>

                <div className="fm-content">
                  <div className="row">
                    <div className="col-md-12">
                      {
                        formData.items.length === 0 ?
                        <div className="nk-block">
                          <div className="text-center m-5">
                            <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                            <div className="price-plan-info">
                              <span className="title fw-normal">No item added</span>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="card my-3">
                          <ul className="nk-support">
                            {
                              formData.items.map((item, index) => (
                                <li className="nk-support-item p-2 pt-4" key={item.item_id}>
                                  <b className="me-2 text-muted">#{index + 1}.</b>
                                  <UserAvatar className="sq lg" theme="lighter" image={item.thumbnail?.length > 0 ? item.thumbnail : imagePlaceholder} imagePlaceholder={imagePlaceholder} />
                                  <div className="nk-support-content">
                                    <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span className="d-none d-lg-block">Remove</span></button>
                                    <div className="row">
                                      <div className="col-md-10">
                                        <div className="title mb-1 py-1">
                                          <span>{item.item_name}</span>
                                        </div>                                 
                                      </div>
    
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <Form.Item>
                                            <Form.Item name={['items', index, 'price']}
                                              noStyle
                                              rules={[
                                                {required: true, message: 'Price is required'}
                                              ]}>
                                              <InputNumber prefix={businessInfo.currency} min={0} step={0.01} disabled size="large" className="w-100" /> 
                                            </Form.Item> 
                                          </Form.Item>
                                        </div>
                                      </div>
    
                                      <div className="col-md-4">
                                        <div className="form-group">
                                        <Form.Item>
                                          <Form.Item name={['items', index, 'quantity']}
                                            noStyle
                                            rules={[
                                              {required: true, message: 'Quantity is required'},
                                              {
                                                validator: (_, value) => {
                                                  // const maxQuantity = productsList.find(product => product.product_name === item.item_name)?.quantity ?? 0;
                                                  if (value > parseInt(item.total_stock)) {
                                                    return Promise.reject(new Error(`Quantity cannot exceed ${item.total_stock}`));
                                                  }
                                                  return Promise.resolve();
                                                },
                                              },
                                            ]}>
                                            <InputNumber min={1} step={1} max={item.total_stock} size="large" className="w-100" />                                    
                                          </Form.Item> 
                                          <Form.Item name={['items', index, 'item_name']} hidden><Input type="hidden" /></Form.Item> 
                                          <Form.Item name={['items', index, 'item_type']} hidden><Input type="hidden" /></Form.Item> 
                                          <Form.Item name={['items', index, 'item_id']} hidden><Input type="hidden" /></Form.Item> 
                                        </Form.Item>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))
                            }
                          </ul>
                        </div>
                      }
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-bordered">
                <div className="fm-content px-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Customer Name <span className="text-muted">(optional)</span></label>
                          </div>
                          <Form.Item name={'customer_name'}
                            noStyle
                            rules={[
                              {required: false,}
                            ]}>
                            <AutoComplete
                              size="large"
                              options={customersList.map(customer => (
                                {
                                  key: customer.customer_id,
                                  label: `${customer.customer_name?.length > 0 ? customer.customer_name : `Customer`} - (${customer.customer_phone_number}) - ID${customer.customer_code}`,
                                  value: `${customer.customer_name?.length > 0 ? customer.customer_name : `Customer`}`,
                                }
                              ))}
                              filterOption={(inputValue, option) =>
                                option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                              }
                              notFoundContent={
                                fetchingCustomers ? 
                                <div className="text-center m-5">
                                  <Spin/>
                                </div> : null}
                              onSelect={handleCustomerSelect}
                              onChange={handleCustomerNameChange}
                              placeholder="Enter customer name"
                              className="w-100"
                            />
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Phone Number <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <PhoneNumberInput form={form} formData={formData} isRequired={false} setFormData={setFormData} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Discount <span className="text-muted">(optional)</span></label>
                          </div>
                          <Form.Item name={'discount_amount'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                            <InputNumber addonAfter={discountTypeOptions} min={0} step={0.01}  size="large" className="w-100" placeholder="eg. 10" />
                          </Form.Item> 
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Tax Profile <span className="text-muted">(optional)</span></label>
                          </div>
                          <div className="form-control-wrap">
                            <Form.Item name={'tax_profile'}
                              noStyle
                              rules={[
                                {required: false}
                              ]}>
                              <Select size="large" 
                                placeholder="Select a tax profile"
                                style={{ width: "100%" }} 
                                allowClear={true}
                                onChange={handleTaxProfileSelect}
                                popupMatchSelectWidth={false}
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <Divider
                                      style={{
                                        margin: '8px 0',
                                      }}
                                    />
                                    <Space
                                      style={{
                                        padding: '0 8px 4px',
                                      }}
                                    >
                                      <Button className="btn-dim btn-blank" onClick={() => {toggleModal('createTaxProfileModal')}}>
                                        <Icon name="plus" />
                                        <span>Create Tax Profile</span>
                                      </Button>
                                    </Space>
                                  </>
                                )}
                                options={taxesList.map(tax => (
                                  {
                                    key: tax.tax_id,
                                    label: tax.tax_profile_name,
                                    value: tax.tax_id,
                                  }
                                ))}
                                showSearch />   
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                {/* summary section */}
                <div className="fm-content bg-lighter">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="nk-iv-scheme-details">
                        <ul className="nk-iv-wg3-list w-100 p-0">
                          <li className="px-0 py-2 fw-medium">
                            <div className="sub-text fs-16px">Sub Total</div>
                            <div className="nk-wg-action-content p-0">
                              <h4 className="amount fs-16px">
                                <small className="text-muted me-1">{businessInfo.currency}</small> 
                                <Numeral value={saleSummary.sub_total.toString()} format={"0,0.00"} />
                              </h4>
                            </div>
                          </li>
                          <li className="px-0 py-2 d-block">
                            {
                              saleSummary.taxes.length > 0 &&
                              <div className="mb-2">
                                {
                                  saleSummary.taxes.map((tax, index) => {
                                    return(
                                      <div className="d-flex justify-content-between mb-1" key={index}>
                                        <div className="sub-text fs-14px">{tax.tax} <small>( {tax.percentage}% )</small></div>
                                        <div className="nk-wg-action-content p-0">
                                          <h4 className="amount fs-14px">
                                            <small className="text-muted me-1">{businessInfo.currency}</small> 
                                            <Numeral value={tax.amount.toString()} format={"0,0.00"} />
                                          </h4>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            }
                            

                            <div className="d-flex justify-content-between fw-medium">
                              <div className="sub-text fs-14px">Total Tax</div>
                              <div className="nk-wg-action-content p-0">
                                <h4 className="amount fs-14px">
                                  <small className="text-muted me-1">{businessInfo.currency}</small> 
                                  <Numeral value={saleSummary.total_tax.toString()} format={"0,0.00"} />
                                </h4>
                              </div>
                            </div>
                          </li>
                          <li className="px-0 py-2">
                            <div className="sub-text fs-14px">Discount</div>
                            <div className="nk-wg-action-content p-0">
                              <h4 className="amount fs-14px">
                                <small className="text-muted me-1">- {businessInfo.currency}</small>
                                <Numeral value={saleSummary.discount_amount.toString()} format={"0,0.00"} />
                                <small className="text-muted"> ({saleSummary.discount_percentage}%)</small>
                              </h4>
                            </div>
                          </li>
                          <li className="px-0 py-2 fw-bold">
                            <div className="sub-text fs-18px">Total</div>
                            <div className="nk-wg-action-content p-0">
                              <h4 className="amount fs-18px">
                                <small className="text-muted me-1">{businessInfo.currency}</small> 
                                <Numeral value={saleSummary.total_amount.toString()} format={"0,0.00"} />
                              </h4>
                            </div>
                          </li>
                          <li className="px-0 py-2 fw-bold">
                            <div className={`sub-text fs-15px ${saleSummary.balance_due > 0 ? `text-danger`: ``}`}>Balance Due</div>
                            <div className="nk-wg-action-content p-0">
                              <h4 className={`amount fs-15px ${saleSummary.balance_due > 0 ? `text-danger`: ``}`}>
                                <small className={`${saleSummary.balance_due > 0 ? `text-danger`: `text-muted`} me-1`}>{businessInfo.currency}</small>
                                <Numeral value={saleSummary.balance_due.toString()} format={"0,0.00"} />
                              </h4>
                            </div>
                          </li>
                          <li className="px-0 py-2">
                            <div className="sub-text fs-14px">Change</div>
                            <div className="nk-wg-action-content p-0">
                              <h4 className={`amount fs-14px ${saleSummary.change > 0 ? `text-success`: ``}`}>
                                <small className={`${saleSummary.change > 0 ? `text-success`: `text-muted`} me-1`}>{businessInfo.currency}</small>
                                <Numeral value={saleSummary.change.toString()} format={"0,0.00"} />
                              </h4>
                            </div>
                          </li> 
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fm-content px-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Payment Type <span className="text-danger">*</span></label>
                          </div>
                          <Form.Item name={'payment_type'}
                            noStyle
                            rules={[
                              {required: true, message: `Select payment type`}
                            ]}>
                            <Select required size="large" 
                              placeholder="Select payment type"
                              style={{ width: "100%" }} 
                              options={[
                                {
                                  key: 'cash',
                                  label: 'Cash',
                                  value: 'cash',
                                },
                                {
                                  key: 'check',
                                  label: 'Check',
                                  value: 'check',
                                }, 
                                {
                                  key: 'card payment',
                                  label: 'Card Payment',
                                  value: 'card payment',
                                },
                                {
                                  key: 'bank',
                                  label: 'Bank Transfer / Deposit',
                                  value: 'bank',
                                }, 
                                {
                                  key: 'mobile money',
                                  label: 'Mobile Money',
                                  value: 'mobile money',
                                },
                                {
                                  key: 'digital wallet',
                                  label: 'Digital Wallet',
                                  value: 'digital wallet',
                                },                              
                              ]}
                              showSearch />  
                          </Form.Item> 
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">                      
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Amount Received <span className="text-danger">*</span></label>
                          </div>
                          <Space.Compact >
                            <Form.Item name={'amount_received'}
                              noStyle
                              rules={[
                                {required: true, message: 'amount received is required'}
                              ]}>
                                  <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                            </Form.Item> 
                            
                            <Button outline type="button" color="primary" className="text-nowrap" onClick={handleApplyTotal}>Full Amount</Button>
                          </Space.Compact>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fm-footer p-3">
                  <div className="text-center">
                    <Button disabled={requesting} className="m-1" type="submit" color="primary" size="lg" >
                      {requesting ? <Spinner size="sm" color="light" /> : <span>Save{`${!isOnline ? ` Offline` : ``}`}</span>}
                    </Button>
                    <Button disabled={requesting} className="m-1" type="button" color="primary" outline size="lg" onClick={() => handleSaleSubmitType('print')} >
                      {requesting ? <Spinner size="sm" color="light" /> : <span>Save{`${!isOnline ? ` Offline` : ``}`} & Print</span>}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Block>
      </>
    }

{ 
      activeModal === "createNewProductModal" && 
      <CreateNewProductFullModal businessID={businessID} currency={businessInfo.currency} toggleModal={() => toggleModal('createNewProductModal')} updateProductList={updateProductList} showModal={true}  /> 
    }

    { 
      activeModal === "successProductModal" &&
      <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Created Successfully`} descriptionText={`The product has been created successfully.`} 
      />
    }

    { 
      activeModal === "createTaxProfileModal" && 
      <CreateTaxProfileModal businessID={businessID} toggleModal={() => toggleModal('createTaxProfileModal')} updateTaxProfiles={updateTaxProfiles} showModal={true}  /> 
    }
    
    { 
      activeModal === "successTaxModal" &&
      <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Created Successfully`} descriptionText={`The tax profile has been created successfully.`} 
      />
    }

    </Content>
    
  </React.Fragment>)
};

export default POS;
